import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as BillingActions from './billing.actions';

export const billingFeatureKey = 'billing';

export interface PaymentMethod {
  payment_method_uuid: string;
  payment_method_type: string;
}

export interface BillingAddress {
  city: string;
  country: string;
  line_1: string;
  line_2: string;
  name: string;
  phone: string;
  post_code: string;
  state: string;
  billing_email: string;
  vat_id: string;
}

export interface Init {
  agbstate: boolean;
  stripe_key: string;
}

export interface BillingMetadata {
  billing_email: string;
  vat_id: string;
}

export interface AvailableMethodsState extends EntityState<PaymentMethod> {
  loading: boolean;
  loaded: boolean;
}

export interface ActivatedMethodsState extends EntityState<PaymentMethod> {
  loading: boolean;
  loaded: boolean;
}

export interface State {
  available: AvailableMethodsState;
  activated: ActivatedMethodsState;
  address: {
    loading: false;
    loaded: false;
    data: BillingAddress;
  };
  billing_metadata: {
    loading: boolean;
    loaded: boolean;
    data: BillingMetadata;
  };
  contract: {
    loading: boolean;
    loaded: boolean;
    data: any;
  };
  init: {
    loading: boolean;
    loaded: boolean;
    data: Init;
  };
}

export const adapterAvailableMethods: EntityAdapter<PaymentMethod> = createEntityAdapter<PaymentMethod>({
  selectId: (method: PaymentMethod) => method.payment_method_uuid,
  sortComparer: false
});

export const adapterActivatedMethods: EntityAdapter<PaymentMethod> = createEntityAdapter<PaymentMethod>({
  selectId: (method: any) => method.id,
  sortComparer: false
});

export const availablePaymentMethodsInitialState: AvailableMethodsState = adapterAvailableMethods.getInitialState({
  loading: false,
  loaded: false
});

export const activatedPaymentMethodsInitialState: ActivatedMethodsState = adapterActivatedMethods.getInitialState({
  loading: false,
  loaded: false
});

const initialState = {
  available: availablePaymentMethodsInitialState,
  activated: activatedPaymentMethodsInitialState,
  address: {
    loading: false,
    loaded: false
  },
  billing_metadata: {
    loading: false,
    loaded: false
  },
  contract: {
    loading: false,
    loaded: false
  },
  init: {
    loading: false,
    loaded: false,
    data: {
      agbstate: false,
      stripe_key: ''
    }
  }
};

export const reducer = createReducer(
  initialState,
  on(BillingActions.loadAvailablePaymentMethods, state => ({
    ...state,
    available: {
      ...state.available,
      loading: true
    }
  })),

  on(BillingActions.loadAvailablePaymentMethodsSuccess, (state, action) => ({
    ...state,
    available: adapterAvailableMethods.setAll(action.data, { ...state.available, loading: false, loaded: true })
  })),

  on(BillingActions.loadAvailablePaymentMethodsFailure, (state, action) => ({
    ...state,
    available: {
      ...state.available,
      loading: false,
      loaded: true,
      error: action.error
    }
  })),

  on(BillingActions.loadActivatedPaymentMethods, state => ({
    ...state,
    activated: {
      ...state.activated,
      loading: true
    }
  })),

  on(BillingActions.loadActivatedPaymentMethodsSuccess, (state, action) => ({
    ...state,
    activated: adapterActivatedMethods.setAll(action.data, { ...state.activated, loading: false, loaded: true })
  })),

  on(BillingActions.loadActivatedPaymentMethodsFailure, (state, action) => ({
    ...state,
    activated: {
      ...state.activated,
      loading: false,
      loaded: true,
      error: action.error
    }
  })),

  on(BillingActions.loadBillingAddress, state => ({
    ...state,
    address: {
      ...state.address,
      loading: true
    }
  })),

  on(BillingActions.loadBillingAddressSuccess, (state, action) => ({
    ...state,
    address: {
      ...state.address,
      loading: false,
      loaded: true,
      data: {
        ...action.data
      }
    }
  })),

  on(BillingActions.loadBillingAddressFailure, (state, action) => ({
    ...state,
    address: {
      ...state.address,
      loading: false,
      loaded: true,
      error: action.error
    }
  })),

  on(BillingActions.loadContract, state => ({
    ...state,
    contract: {
      ...state.contract,
      loading: true
    }
  })),

  on(BillingActions.loadContractSuccess, (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      loading: false,
      loaded: true,
      data: {
        ...action.data
      }
    }
  })),

  on(BillingActions.loadContractFailure, (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      loading: false,
      loaded: true,
      error: action.error
    }
  })),

  on(BillingActions.saveContract, state => ({
    ...state,
    contract: {
      ...state.contract,
      loading: true
    }
  })),

  on(BillingActions.saveContractSuccess, (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      loading: false,
      data: {
        ...action.data
      }
    }
  })),

  on(BillingActions.saveContractFailure, (state, action) => ({
    ...state,
    contract: {
      ...state.contract,
      loading: false,
      error: action.error
    }
  })),

  on(BillingActions.getInit, (state, action) => ({
    ...state,
    init: {
      ...state.init,
      loading: true
    }
  })),

  on(BillingActions.getInitSuccess, (state, action) => ({
    ...state,
    init: {
      ...state.init,
      loading: false,
      loaded: true,
      data: {
        ...state.init.data,
        agbstate: action.data.account.agbstate,
        stripe_key: action.data.stripe_key
      }
    }
  })),

  on(BillingActions.getInitFailure, (state, action) => ({
    ...state,
    init: {
      ...state.init,
      loading: false,
      loaded: false
    }
  })),

  on(BillingActions.setAgbStateSuccess, state => ({
    ...state,
    init: {
      ...state.init,
      data: {
        ...state.init.data,
        agbstate: true
      }
    }
  }))
);

export const selectAvailablePaymentMethodsState = (state: State) => state.available;
export const selectActivatedPaymentMethodsState = (state: State) => state.activated;

export const { selectAll: selectAllAvailablePaymentMethods, selectEntities: selectAvailablePaymentMethodsEntities } = adapterAvailableMethods.getSelectors();
export const { selectAll: selectAllActivatedPaymentMethods, selectEntities: selectActivatedPaymentMethodsEntities } = adapterActivatedMethods.getSelectors();
