import { createAction, props } from '@ngrx/store';

export const actionPrefix = '[Billing]';

// @TODO: Remove when php moved stuff to new endpoint
export const getInit = createAction(actionPrefix + ' Get init stuff');
export const getInitSuccess = createAction(actionPrefix + ' Get init stuff Success', props<{ data: any }>());
export const getInitFailure = createAction(actionPrefix + ' Get init stuff Failure', props<{ error: Error }>());

export const loadAvailablePaymentMethods = createAction(actionPrefix + ' Load available payment methods');
export const loadAvailablePaymentMethodsSuccess = createAction(actionPrefix + ' Load available payment methods Success', props<{ data: any }>());
export const loadAvailablePaymentMethodsFailure = createAction(actionPrefix + ' Load available payment methods Failure', props<{ error: Error }>());

export const loadActivatedPaymentMethods = createAction(actionPrefix + ' Load activated payment methods');
export const loadActivatedPaymentMethodsSuccess = createAction(actionPrefix + ' Load activated payment methods Success', props<{ data: any }>());
export const loadActivatedPaymentMethodsFailure = createAction(actionPrefix + ' Load activated payment methods Failure', props<{ error: Error }>());

export const addPaymentMethod = createAction(actionPrefix + ' Add payment method', props<{ name: string; payment_method_details: any }>());
export const addPaymentMethodSuccess = createAction(actionPrefix + ' Add payment method success', props<{ data: any }>());
export const addPaymentMethodFailure = createAction(actionPrefix + ' Add payment method failure', props<{ error: Error }>());

export const loadBillingAddress = createAction(actionPrefix + ' Load billing address');
export const loadBillingAddressSuccess = createAction(actionPrefix + ' Load billing address success', props<{ data: any }>());
export const loadBillingAddressFailure = createAction(actionPrefix + ' Load billing address failure', props<{ error: Error }>());

export const loadContract = createAction(actionPrefix + ' Load contract');
export const loadContractSuccess = createAction(actionPrefix + ' Load contract success', props<{ data: any }>());
export const loadContractFailure = createAction(actionPrefix + ' Load contract failure', props<{ error: Error }>());

export const saveContract = createAction(actionPrefix + ' save contract', props<{ data: any }>());
export const saveContractSuccess = createAction(actionPrefix + ' save contract success', props<{ data: any }>());
export const saveContractFailure = createAction(actionPrefix + ' save contract failure', props<{ error: Error }>());

export const addBillingAddress = createAction(actionPrefix + ' Add billing address', props<{ data: any }>());
export const addBillingAddressSuccess = createAction(actionPrefix + ' Add billing address success', props<{ data: any }>());
export const addBillingAddressFailure = createAction(actionPrefix + ' Add billing address failure', props<{ error: Error }>());

export const saveBillingMetadata = createAction(actionPrefix + ' save billing metadata', props<{ data: any }>());
export const saveBillingMetadataSuccess = createAction(actionPrefix + ' save billing metadata success', props<{ data: any }>());
export const saveBillingMetadataFailure = createAction(actionPrefix + ' save billing metadata failure', props<{ error: Error }>());

export const removePaymentMethod = createAction(actionPrefix + ' Remove payment method', props<{ id: string }>());
export const removePaymentMethodSuccess = createAction(actionPrefix + ' Remove payment method success', props<{ data: any }>());
export const removePaymentMethodFailure = createAction(actionPrefix + ' Remove payment method failure', props<{ error: Error }>());

export const setPaymentMethodAsDefault = createAction(actionPrefix + ' Set payment method as default', props<{ id: string }>());
export const setPaymentMethodAsDefaultSuccess = createAction(actionPrefix + ' Set payment method as default success', props<{ data: any }>());
export const setPaymentMethodAsDefaultFailure = createAction(actionPrefix + ' Set payment method as default failure', props<{ error: Error }>());

export const updatePaymentMethod = createAction(actionPrefix + ' Update payment method', props<{ id: string; name: string; payment_method_details: any }>());
export const updatePaymentMethodSuccess = createAction(actionPrefix + ' Update payment method success', props<{ data: any }>());
export const updatePaymentMethodFailure = createAction(actionPrefix + ' Update payment method failure', props<{ error: Error }>());

export const validateSepa = createAction(actionPrefix + ' Validate SEPA', props<{ paymentMethodUuid: string; verify_pin: string }>());
export const validateSepaSuccess = createAction(actionPrefix + ' Validate SEPA success');
export const validateSepaFailure = createAction(actionPrefix + ' Validate SEPA failure', props<{ error: Error }>());

export const setAgbState = createAction(actionPrefix + ' Set AGB State');
export const setAgbStateSuccess = createAction(actionPrefix + ' Set AGB State success');
export const setAgbStateFailure = createAction(actionPrefix + ' Set AGB State failure', props<{ error: Error }>());
