import { selectRouteNestedParam } from './../../store/router/router.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import * as fromBilling from './billing.reducer';

export const selectBillingState = createFeatureSelector<fromBilling.State>(fromBilling.billingFeatureKey);
const selectAvailableState = createSelector(selectBillingState, fromBilling.selectAvailablePaymentMethodsState);
const selectActivatedState = createSelector(selectBillingState, fromBilling.selectActivatedPaymentMethodsState);

export const isAvailablePaymentMethodsLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['available', 'loading'], false));
export const isAvailablePaymentMethodsLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['available', 'loaded'], false));
export const getAvailablePaymentMethods = createSelector(selectAvailableState, fromBilling.selectAllAvailablePaymentMethods);

export const isActivatedPaymentMethodsLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['activated', 'loading'], false));
export const isActivatedPaymentMethodsLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['activated', 'loaded'], false));
export const getActivatedPaymentMethods = createSelector(selectActivatedState, fromBilling.selectAllActivatedPaymentMethods);

export const isBillingAddressLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['address', 'loading'], false));
export const isBillingAddressLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['address', 'loaded'], false));
export const getBillingAddress = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['address', 'data'], null));

export const isBillingMetadataLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['billing_metadata', 'loading'], false));
export const isBillingMetadataLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['billing_metadata', 'loaded'], false));
export const getBillingMetadata = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['billing_metadata', 'data'], null));

export const isContractLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['contract', 'loading'], false));
export const isContractLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['contract', 'loaded'], false));
export const getContract = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['contract', 'data', 'attributes'], null));

export const getSelectedMethod = createSelector(selectBillingState, selectRouteNestedParam('method_uuid'), (state: fromBilling.State, method_uuid) =>
  _.get(fromBilling.selectActivatedPaymentMethodsEntities(state.activated), [method_uuid])
);

export const getAgbState = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['init', 'data', 'agbstate'], false));
export const getStripeKey = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['init', 'data', 'stripe_key'], ''));
export const isInitLoading = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['init', 'loading'], false));
export const isInitLoaded = createSelector(selectBillingState, (state: fromBilling.State) => _.get(state, ['init', 'loaded'], false));

export const hasUnvalidatedSepa = createSelector(getActivatedPaymentMethods, (state: any) =>
  _.find(state, o => o.attributes?.payment_method_type === 'sepa' && o.attributes?.state === 'unvalidated') ? true : false
);
